.hero-image-container {
    height: 100%;
}

.modal {
    font-size: 12px;
    background-color: whitesmoke;
    padding: 1rem;
    border: solid 1.5px hsl(203, 3%, 20%);
    max-width: 850px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
}

.modal>.header {
    border-bottom: 1px solid gray;
    text-align: center;
    padding: 10px;
}

.modal>.content {
    width: 100%;
    padding: 10px 5px;
}

.modal p {
    margin: 1rem 0;
}

.modal li {
    line-height: 1.5;
}

.modal>.actions {
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.modal-button {
    width: 140px;
    margin: 5px 7px;
}

.modal>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 0 6.5px;
    line-height: 1;
    right: 10px;
    top: 10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #cfcece;
}

/* Natural breakpoint 875px */

@media only screen and (max-width: 874px) {
    .modal {
        margin-left: 18px;
        margin-right: 18px;
    }
}