@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Maven+Pro&family=Zilla+Slab:wght@300;600&display=swap);
/* Font styles */
h1 {
  font-family: 'Zilla Slab', serif;
  font-size: 3rem;
  margin: 7% 0 0 0;
}

h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  margin: 0;
}

h3 {
  font-family: 'Maven Pro', sans-serif;
}

h4 {
  font-family: 'Zilla Slab', serif;
  font-size: 1.2rem;
  margin: 0;
}

p {
  font-family: 'Maven Pro', sans-serif;
  font-weight: lighter;
  font-size: 0.9rem;
  line-height: 1.25;
  margin: 0;
}

button, input[type=submit] {
  font-family: 'Zilla Slab', serif;
  font-size: 0.9rem;
  margin: 0;
  white-space: nowrap;
  text-align: center;
}

input[type=text], input[type=email], textarea {
  font-family: 'Maven Pro', sans-serif;
  font-size: 0.85rem;
}

.thumbnail-label p, .circle-shape p {
  font-family: 'Zilla Slab', serif;
  font-size: 0.9rem;
  line-height: 1.5;
  text-align: center;
}

/* General styles */

body {
  background-color: hsl(210, 14%, 19%);
  position: relative;
  min-height: 100vh;
}

.zoom {
  transition: -webkit-transform .1s;
  transition: transform .1s;
  transition: transform .1s, -webkit-transform .1s;
}

.zoom:hover {
  -webkit-transform: scale(1.018);
          transform: scale(1.018);
}

.pillbox-button {
  cursor: pointer;
  border: solid 1.5px hsl(203, 3%, 20%);
  border-radius: 20px;
  padding: 5px 10px;
  background-color: hsl(0, 0%, 94%);
}

/* Name the grid areas */

header {
  grid-area: header;
}

#intro-container {
  grid-area: intro;
}

#profile-photo-container {
  grid-area: photo;
}

#responsive-website-container {
  grid-area: responsive;
}

#pern-web-app-container {
  grid-area: pern;
}

#javascript-game-container {
  grid-area: game;
}

#listmaker-app-container {
  grid-area: listmaker;
}

#secondary-profile-photo-container {
  grid-area: secondary;
}

#interactive-website-container {
  grid-area: interactive;
}

#portfolio-container {
  grid-area: portfolio;
}

.contact-icons-container {
  grid-area: contact;
}

footer {
  grid-area: footer;
}

/* Main grid items layout and appearance */

#portfolio-main {
  margin: 40px auto 20px auto;
  display: grid;
  width: 85%;
  grid-template-columns: repeat(8, 8.5vw);
  grid-template-rows: repeat(12, 8.5vw);
  grid-template-areas: "intro      intro       intro         intro     intro     header    header    header" "intro      intro       intro         intro     intro     photo     photo     photo" "intro      intro       intro         intro     intro     photo     photo     photo" "intro      intro       intro         intro     intro     photo     photo     photo" "interactive interactive interactive  pern      pern      pern      pern      pern" "interactive interactive interactive  pern      pern      pern      pern      pern" "interactive interactive interactive  pern      pern      pern      pern      pern" "game       game        game          pern      pern      pern      pern      pern" "game       game        game          pern      pern      pern      pern      pern" "game       game        game          listmaker listmaker listmaker secondary secondary" "contact          portfolio   portfolio     listmaker listmaker listmaker secondary secondary" "contact          portfolio   portfolio     listmaker listmaker listmaker footer    footer";
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
  -webkit-justify-content: center;
          justify-content: center;
}

/* Header */

header {
  text-align: center;
}

header a {
  color: hsl(32, 49%, 92%);
  text-decoration: none;
}

/* Intro container and contact icons container */

#intro-container {
  background-color: hsl(32, 49%, 92%);
  color: black;
  box-sizing: border-box;
  padding: 1.5rem;
  border-radius: 2px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-content: space-between;
          align-content: space-between;
}

#buttons-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

.contact-icons-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.contact-button {
  /* Intro container and Contact page */
  color: black;
  height: 2.5rem;
  font-size: 1rem !important;
  margin-right: 10px;
}

.contact-button {
  border: solid 1.5px hsl(203, 3%, 20%);
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

.social-media-icon {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

svg {
  height: 2.5rem;
  width: 2.5rem;
}

#intro-container a, .contact-icons-container a {
  height: 2.5rem;
}

.contact-icon a, .contact-icon svg {
  height: 2.7rem;
  width: 2.7rem
}

#intro-container .social-media-icon {
  margin-right: 10px;
}

.contact-icons-container .github-link {
  margin-top: 8px;
}

.contact-icons-container .linkedin-link {
  margin-top: 10px;
}

.contact-icons-container path {
  fill: hsl(32, 49%, 92%);
}

#intro-container .social-media-icon:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

.contact-icons-container .social-media-icon:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* Hero Image, Video and Image Thumbnails */

.embedded-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
}

.hero-image-container, .video-thumbnail-container, .image-thumbnail-container {
  position: relative;
}

.learn-more-button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: hsl(203, 3%, 20%);
  color: hsl(32, 49%, 92%); 
  border: 1.5px hsl(32, 49%, 92%) solid;
}

.learn-more-button:hover {
  color: hsl(203, 3%, 20%);
  background-color: hsl(32, 49%, 92%); 
  border: solid 1px hsl(203, 3%, 20%);
}

.hero-image-container .learn-more-button {
  top: 55%;
  left: 50%;
}

.middle-circle {
  position: absolute;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 30%;
  height: 30%;
  border: 1.5px hsl(32, 49%, 92%) solid;
}

.circle-shape {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  /* border: solid 1.5px hsl(203, 3%, 20%); */
  background-color: hsl(203, 3%, 20%);
  color: hsl(32, 49%, 92%);
  border-radius: 50%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  padding: 0.4rem;
}

.label-button-container {
  position: absolute;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: inline;
  text-align: center;
}

.thumbnail-label {
  /* background-color: white; */
  background-color: hsl(203, 3%, 20%);
  color: hsl(32, 49%, 92%);
  opacity: 1;
  border: 1.5px hsl(32, 49%, 92%) solid;
  border-radius: 3px;
  padding: 10px;
  text-align: center;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.mobile-label-button-container {
  display: none;
}

.mobile-circular-label-container {
  display: none;
}

/* Footer */

footer {
  color: #B3BFBC;
  padding: 0;
  margin: 0;
  text-align: center;
}

footer p {
  margin-top: 1rem;
}

/* Contact Form */

#contact-main {
  margin-top: 40px;
}

#contact-main a {
  text-decoration: none;
}

#contact-form-container h1 {
  color: hsl(203, 3%, 20%);
  margin-top: 4%;
}

#contact-form-container {
  max-width: 600px;
  border: 1px solid black;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  vertical-align: top;
  background-color: hsl(32, 49%, 92%);
  color: black;
  text-align: center;
  margin: 1rem auto 2rem auto;
}

#contact-form {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.form-row {
  width: 94%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 10px 0;
}

#name {
  width: 37%;
  margin-right: 3%;
}

#email {
  width: 50%;
}

#subject, #message {
  width: 100%;
}

#contact-form input[type=text], #contact-form input[type=email], #contact-form textarea {
  padding: 5px 10px;
}

#contact-form textarea {
  height: 175px;
  resize: vertical;
}

#submit-row {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

#contact-form .contact-button {
  width: 135px;
  padding: 5px;
}

#contact-submission-status {
  margin: 1rem;
}

#contact-main .contact-icons-container {
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

#contact-main .contact-icons-container a {
  margin: 0 8px;
}

#contact-main footer {
  margin-bottom: 25px;
}
/* Larger screen sizes */

@media only screen and (min-width: 950px) /* and (max-width: 1214px) */ {
    /* Main grid items layout and appearance */
    #portfolio-main {
        grid-template-columns: repeat(12, 5.5vw);
        grid-template-rows: repeat(13, 5.5vw);
        grid-template-areas: 
        ".          intro            intro        intro       intro        intro     intro     intro    header    header    header    ." 
        ".          intro            intro        intro       intro        intro     intro     intro     photo     photo     photo  ." 
        ".          intro            intro        intro       intro        intro     intro     intro     photo     photo     photo   ."  
        ".          intro            intro        intro       intro        intro     intro     intro     photo     photo    photo    ."  
        "interactive interactive interactive  interactive pern         pern      pern      pern      pern      game      game    game" 
        "interactive interactive interactive  interactive pern         pern      pern      pern      pern      game      game    game" 
        "interactive interactive interactive  interactive pern         pern      pern      pern      pern      game      game    game" 
        "interactive interactive interactive  interactive pern         pern      pern      pern      pern      secondary secondary ." 
        ".           listmaker   listmaker    listmaker   pern         pern      pern      pern      pern      secondary secondary ." 
        ".           listmaker   listmaker    listmaker   portfolio    portfolio portfolio portfolio contact    .           .       ." 
        ".           listmaker   listmaker    listmaker   portfolio    portfolio portfolio portfolio contact    .           .       ."
        ".          footer       footer       footer    portfolio    portfolio portfolio portfolio contact    .           .       ."
        ".          footer       footer        footer     portfolio    portfolio portfolio portfolio contact    .           .       .";
        grid-column-gap: 25px;
        -webkit-column-gap: 25px;
                column-gap: 25px;
        grid-row-gap: 25px;
        row-gap: 25px;
    }

    .contact-icons-container a {
        margin: 8px 0;
    }
}

@media only screen and (min-width: 1215px) {
    /* Main grid items layout and appearance */
    #portfolio-main {
        grid-template-columns: repeat(12, 5.5vw);
        grid-template-rows: repeat(13, 5.5vw);
        grid-template-areas: 
        ".          .            intro        intro       intro        intro     intro     header    header    header    .       ." 
        ".          .            intro        intro       intro        intro     intro     photo     photo     photo     .       ." 
        ".          .            intro        intro       intro        intro     intro     photo     photo     photo     .       ."  
        ".          .            intro        intro       intro        intro     intro     photo     photo     photo     .       ."  
        "interactive interactive interactive  interactive pern         pern      pern      pern      pern      game      game    game" 
        "interactive interactive interactive  interactive pern         pern      pern      pern      pern      game      game    game" 
        "interactive interactive interactive  interactive pern         pern      pern      pern      pern      game      game    game" 
        "interactive interactive interactive  interactive pern         pern      pern      pern      pern      secondary secondary ." 
        ".           listmaker   listmaker    listmaker   pern         pern      pern      pern      pern      secondary secondary ." 
        ".           listmaker   listmaker    listmaker   portfolio    portfolio portfolio portfolio contact    .           .       ." 
        ".           listmaker   listmaker    listmaker   portfolio    portfolio portfolio portfolio contact    .           .       ."
        ".          footer       footer       footer    portfolio    portfolio portfolio portfolio contact    .           .       ."
        ".          footer       footer        footer     portfolio    portfolio portfolio portfolio contact    .           .       .";
        grid-column-gap: 25px;
        -webkit-column-gap: 25px;
                column-gap: 25px;
        grid-row-gap: 25px;
        row-gap: 25px;
    }
}

@media only screen and (min-width: 1500px) {
    header h1 { 
        margin-top: 10%;
    }
}

/* Smaller screen sizes */

@media only screen and (max-width: 849px) {
    .middle-circle {
        width: 31%;
    }
}

/* Tablet breakpoint 768px (loss of mouse) */

@media only screen and (max-width: 768px) {
    /* Turn off zoom */
    .zoom {
        transition: none;
    }
    .zoom:hover {
        -webkit-transform: none;
                transform: none;
    }
    /* Intro container and contact icons container */
    svg {
        height: 2.3rem;
        width: 2.3rem;
    }
    
    #intro-container a, .contact-icons-container a {
        height: 2.3rem;
    }
    .contact-icon a, .contact-icon svg {
        height: 2.4rem;
        width: 2.4rem
    }
    /* Hero Image, Video and Image Thumbnails */
    .middle-circle p, .circle-when-tablet p, .thumbnail-label p, .learn-more-button {
        font-size: 0.7rem;
    }
    .computer-circular-label-container {
        /* Label and button on hero image */
        display: none;
    }
    .computer-label-button-container {
        /* Label and button on video and image thumbnails */
        display: none;
    }
    .hero-image-container .mobile-circular-label-container {
        display: inline-block;
    }
    .mobile-label-button-container {
        /* Video and image thumbnails */
        display: inline-block;
    }
    .hero-image-container .middle-circle {
        top: 70%;
        left: 50%;
    }
    .mobile-label-button-container .learn-more-button {
        position: static;
        margin-top: 0.15rem;
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    .hero-image-container .mobile-circular-label-container .learn-more-button {
        top: 79%;
    }
}

/* Natural breakpoint 750px */

@media only screen and (max-width: 750px) {
    #portfolio-main {
        grid-template-areas: "intro   intro    intro      intro      intro     header      header      header" "intro   intro    intro      intro      intro     photo       photo       photo" "intro   intro    intro      intro      intro     photo       photo       photo" "intro   intro    intro      intro      intro     photo       photo       photo" "intro   intro    intro      intro      intro     interactive interactive interactive" "pern    pern     pern       pern       pern      interactive interactive interactive" "pern    pern     pern       pern       pern      interactive interactive interactive" "pern    pern     pern       pern       pern      game        game        game" "pern    pern     pern       pern       pern      game        game        game" "pern    pern     pern       pern       pern      game        game        game" "contact  contact   portfolio  portfolio  listmaker listmaker   secondary   secondary " "footer footer  portfolio  portfolio  listmaker listmaker   secondary   secondary ";
    }
    h1 {
        margin-top: 4%;
    }
    .contact-icons-container {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: flex-end;
                align-items: flex-end;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .contact-icons-container a {
        margin: 0 4px;
    }
    .contact-icon a, .contact-icon svg {
        height: 2.5rem;
        width: 2.5rem;
    }
}

/* Natural breakpoint 715px */

@media only screen and (max-width: 715px) {
    .thumbnail-label p, .learn-more-button {
        font-size: 0.6rem;
    }
}

/* Natural breakpoint 660px */

@media only screen and (max-width: 660px) {
    svg {
        height: 2.1rem;
        width: 2.1rem;
    }
    #intro-container a, .contact-icons-container a {
        height: 2.1rem;
    }
    .contact-icon a, .contact-icon svg {
        height: 2.2rem;
        width: 2.2rem
    }
    .middle-circle {
        width: 35%;
        height: 35%;
    }
    .mobile-circular-label-container .learn-more-button {
        top: 81%;
    }
    .thumbnail-label p, .learn-more-button {
        font-size: 0.55rem;
    }
    #contact-main {
        padding: 0 10px;
    }
}

/* Natural breakpoint 603px */

@media only screen and (max-width: 603px) {
    .thumbnail-label p, .learn-more-button {
        font-size: 0.5rem;
    }
    #submit-row {
        -webkit-justify-content: center;
                justify-content: center;
    }
    #contact-main .contact-button {
        margin: 0 5px;
    }
}

/* Natural breakpoint 590px */

@media only screen and (max-width: 590px) {
    #portfolio-main {
        grid-template-columns: repeat(8, 10.5vw);
        grid-template-rows: repeat(13, 10.5vw) 6vw;
        grid-template-areas: "intro   intro    intro      intro      intro     header      header      header" "intro   intro    intro      intro      intro     photo       photo       photo" "intro   intro    intro      intro      intro     photo       photo       photo" "intro   intro    intro      intro      intro     photo       photo       photo" "intro   intro    intro      intro      intro     interactive interactive interactive" "intro   intro    intro      intro      intro     interactive interactive interactive" "pern    pern     pern       pern       pern      interactive interactive interactive" "pern    pern     pern       pern       pern      game        game        game" "pern    pern     pern       pern       pern      game        game        game" "pern    pern     pern       pern       pern      game        game        game" "pern    pern     pern       pern       pern      portfolio  portfolio    portfolio" "contact  secondary   secondary  listmaker  listmaker portfolio  portfolio    portfolio" "contact  secondary   secondary  listmaker  listmaker portfolio  portfolio    portfolio" "footer footer footer footer footer footer footer footer";
    }
    h1 {
        font-size: 2.5rem;
        margin-top: 4%;
    }
    h2 {
        font-size: 1.75rem;
    }
    .contact-icons-container {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }
    .contact-icons-container a {
        margin: 0;
    }
    .middle-circle {
        height: 34%;
    }
}

/* Natural breakpoint 564px */

@media only screen and (max-width: 564px) {
    #portfolio-main {
        margin-top: 27px;
        grid-template-columns: 94vw;
        grid-template-rows: 12vw repeat(8, 94vw) 10vw 10vw;
        grid-template-areas: "header  " "intro   " "photo   " "pern    " "interactive " "game   " "portfolio  " "listmaker  " "secondary  " "contact   " "footer ";
    }
    .contact-icons-container {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: flex-end;
                align-items: flex-end;
    }
    .contact-icons-container a {
        margin: 0 8px;
    }
    .middle-circle {
        width: 30%;
        height: 30%;
    }
    .video-thumbnail-container .middle-circle, .image-thumbnail-container .middle-circle {
        top: 50%;
    }
    .video-thumbnail-container .mobile-circular-label-container .learn-more-button, .image-thumbnail-container .mobile-circular-label-container .learn-more-button {
        top: 59%;
    }
    .middle-circle p, .thumbnail-label p {
        font-size: 0.9rem;
    }
    .learn-more-button {
        font-size: 0.8rem;
    }
    .mobile-label-button-container {
        display: none;
    }
    .video-thumbnail-container .mobile-circular-label-container, .image-thumbnail-container .mobile-circular-label-container {
        display: inline-block;
    }
    h1 {
        font-size: 3rem;
        margin: 0;
    }
    h2 {
        font-size: 2rem;
    }
    #intro-container p, input[type=text], input[type=email], textarea {
        font-size: 1rem;
    }
    #get-in-touch-button {
        font-size: 1rem;
    }
    footer {
        -webkit-transform: rotate(0) translate(0);
                transform: rotate(0) translate(0);
    }
}

/* Natural breakpoint 549px */

@media only screen and (max-width: 549px) {
    .middle-circle {
        width: 34%;
        height: 34%;
    }
    .mobile-circular-label-container .learn-more-button {
        top: 80%;
    }
    .video-thumbnail-container .mobile-circular-label-container .learn-more-button, .image-thumbnail-container .mobile-circular-label-container .learn-more-button {
        top: 60%;
    }
}

/* Natural breakpoint 506px */

@media only screen and (max-width: 506px) {
    .middle-circle {
        width: 38%;
        height: 38%;
    }
    .hero-image-container .middle-circle {
        top: 73%;
    }
    .hero-image-container .mobile-circular-label-container .learn-more-button {
        top: 84%;
    }
    .video-thumbnail-container .mobile-circular-label-container .learn-more-button, .image-thumbnail-container .mobile-circular-label-container .learn-more-button {
        top: 61%;
    }
}

/* Natural breakpoint 454px */

@media only screen and (max-width: 454px) {
    #portfolio-main {
        grid-template-rows: 13vw 100vw repeat(7, 94vw) 10vw 10vw;
    }
    .middle-circle {
        width: 41%;
        height: 41%;
    }
    .hero-image-container .middle-circle {
        top: 50%;
    }
    .learn-more-button {
        top: 62.5% !important;
    }
}

/* Natural breakpoint 444px */

@media only screen and (max-width: 444px) {
    #portfolio-main {
        grid-template-rows: 16vw 120vw repeat(7, 94vw) 10vw;
    }
    .middle-circle {
        width: 43%;
        height: 43%;
    }
}

/* Natural breakpoint 399px */

@media only screen and (max-width: 399px) {
    #portfolio-main {
        margin-top: 22px;
        grid-template-rows: 18vw 120vw repeat(7, 94vw) 12vw 10vw;
    }
    .middle-circle {
        width: 45%;
        height: 45%;
    }
}

/* Natural breakpoint 382px */

@media only screen and (max-width: 382px) {
    .middle-circle {
        width: 48%;
        height: 48%;
    }
    footer p {
        margin-top: 0.5rem;
    }
}

@media only screen and (max-width: 358px) {
    #portfolio-main {
        grid-template-rows: 18vw 130vw repeat(7, 94vw) 14vw 10vw;
    }
    .middle-circle {
        width: 52%;
        height: 52%;
    }
    .learn-more-button {
        top: 64.5% !important;
    }
}

@media only screen and (max-width: 334px) {
    #portfolio-main {
        grid-template-rows: 18vw 130vw repeat(7, 94vw) 14vw 10vw;
    }
    .learn-more-button {
        top: 64.5% !important;
    }
}

.hero-image-container {
    height: 100%;
}

.modal {
    font-size: 12px;
    background-color: whitesmoke;
    padding: 1rem;
    border: solid 1.5px hsl(203, 3%, 20%);
    max-width: 850px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
}

.modal>.header {
    border-bottom: 1px solid gray;
    text-align: center;
    padding: 10px;
}

.modal>.content {
    width: 100%;
    padding: 10px 5px;
}

.modal p {
    margin: 1rem 0;
}

.modal li {
    line-height: 1.5;
}

.modal>.actions {
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.modal-button {
    width: 140px;
    margin: 5px 7px;
}

.modal>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 0 6.5px;
    line-height: 1;
    right: 10px;
    top: 10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #cfcece;
}

/* Natural breakpoint 875px */

@media only screen and (max-width: 874px) {
    .modal {
        margin-left: 18px;
        margin-right: 18px;
    }
}
.lds-circle {
    display: inline-block;
    -webkit-transform: translateZ(1px);
            transform: translateZ(1px);
}

.lds-circle>div {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0;
    border-radius: 50%;
    background: rgb(172, 198, 247);
    -webkit-animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@-webkit-keyframes lds-circle {
    0%, 100% {
        -webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
                animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
    }
    50% {
        -webkit-transform: rotateY(1800deg);
                transform: rotateY(1800deg);
        -webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
                animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
        -webkit-transform: rotateY(3600deg);
                transform: rotateY(3600deg);
    }
}

@keyframes lds-circle {
    0%, 100% {
        -webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
                animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
    }
    50% {
        -webkit-transform: rotateY(1800deg);
                transform: rotateY(1800deg);
        -webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
                animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
        -webkit-transform: rotateY(3600deg);
                transform: rotateY(3600deg);
    }
}
