/* Font styles */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Maven+Pro&family=Zilla+Slab:wght@300;600&display=swap');
h1 {
  font-family: 'Zilla Slab', serif;
  font-size: 3rem;
  margin: 7% 0 0 0;
}

h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  margin: 0;
}

h3 {
  font-family: 'Maven Pro', sans-serif;
}

h4 {
  font-family: 'Zilla Slab', serif;
  font-size: 1.2rem;
  margin: 0;
}

p {
  font-family: 'Maven Pro', sans-serif;
  font-weight: lighter;
  font-size: 0.9rem;
  line-height: 1.25;
  margin: 0;
}

button, input[type=submit] {
  font-family: 'Zilla Slab', serif;
  font-size: 0.9rem;
  margin: 0;
  white-space: nowrap;
  text-align: center;
}

input[type=text], input[type=email], textarea {
  font-family: 'Maven Pro', sans-serif;
  font-size: 0.85rem;
}

.thumbnail-label p, .circle-shape p {
  font-family: 'Zilla Slab', serif;
  font-size: 0.9rem;
  line-height: 1.5;
  text-align: center;
}

/* General styles */

body {
  background-color: hsl(210, 14%, 19%);
  position: relative;
  min-height: 100vh;
}

.zoom {
  transition: transform .1s;
}

.zoom:hover {
  transform: scale(1.018);
}

.pillbox-button {
  cursor: pointer;
  border: solid 1.5px hsl(203, 3%, 20%);
  border-radius: 20px;
  padding: 5px 10px;
  background-color: hsl(0, 0%, 94%);
}

/* Name the grid areas */

header {
  grid-area: header;
}

#intro-container {
  grid-area: intro;
}

#profile-photo-container {
  grid-area: photo;
}

#responsive-website-container {
  grid-area: responsive;
}

#pern-web-app-container {
  grid-area: pern;
}

#javascript-game-container {
  grid-area: game;
}

#listmaker-app-container {
  grid-area: listmaker;
}

#secondary-profile-photo-container {
  grid-area: secondary;
}

#interactive-website-container {
  grid-area: interactive;
}

#portfolio-container {
  grid-area: portfolio;
}

.contact-icons-container {
  grid-area: contact;
}

footer {
  grid-area: footer;
}

/* Main grid items layout and appearance */

#portfolio-main {
  margin: 40px auto 20px auto;
  display: grid;
  width: 85%;
  grid-template-columns: repeat(8, 8.5vw);
  grid-template-rows: repeat(12, 8.5vw);
  grid-template-areas: "intro      intro       intro         intro     intro     header    header    header" "intro      intro       intro         intro     intro     photo     photo     photo" "intro      intro       intro         intro     intro     photo     photo     photo" "intro      intro       intro         intro     intro     photo     photo     photo" "interactive interactive interactive  pern      pern      pern      pern      pern" "interactive interactive interactive  pern      pern      pern      pern      pern" "interactive interactive interactive  pern      pern      pern      pern      pern" "game       game        game          pern      pern      pern      pern      pern" "game       game        game          pern      pern      pern      pern      pern" "game       game        game          listmaker listmaker listmaker secondary secondary" "contact          portfolio   portfolio     listmaker listmaker listmaker secondary secondary" "contact          portfolio   portfolio     listmaker listmaker listmaker footer    footer";
  column-gap: 20px;
  row-gap: 20px;
  justify-content: center;
}

/* Header */

header {
  text-align: center;
}

header a {
  color: hsl(32, 49%, 92%);
  text-decoration: none;
}

/* Intro container and contact icons container */

#intro-container {
  background-color: hsl(32, 49%, 92%);
  color: black;
  box-sizing: border-box;
  padding: 1.5rem;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

#buttons-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contact-icons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-button {
  /* Intro container and Contact page */
  color: black;
  height: 2.5rem;
  font-size: 1rem !important;
  margin-right: 10px;
}

.contact-button {
  border: solid 1.5px hsl(203, 3%, 20%);
  filter: brightness(140%);
}

.social-media-icon {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

svg {
  height: 2.5rem;
  width: 2.5rem;
}

#intro-container a, .contact-icons-container a {
  height: 2.5rem;
}

.contact-icon a, .contact-icon svg {
  height: 2.7rem;
  width: 2.7rem
}

#intro-container .social-media-icon {
  margin-right: 10px;
}

.contact-icons-container .github-link {
  margin-top: 8px;
}

.contact-icons-container .linkedin-link {
  margin-top: 10px;
}

.contact-icons-container path {
  fill: hsl(32, 49%, 92%);
}

#intro-container .social-media-icon:hover {
  filter: brightness(140%);
}

.contact-icons-container .social-media-icon:hover {
  transform: scale(1.1);
}

/* Hero Image, Video and Image Thumbnails */

.embedded-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
}

.hero-image-container, .video-thumbnail-container, .image-thumbnail-container {
  position: relative;
}

.learn-more-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: hsl(203, 3%, 20%);
  color: hsl(32, 49%, 92%); 
  border: 1.5px hsl(32, 49%, 92%) solid;
}

.learn-more-button:hover {
  color: hsl(203, 3%, 20%);
  background-color: hsl(32, 49%, 92%); 
  border: solid 1px hsl(203, 3%, 20%);
}

.hero-image-container .learn-more-button {
  top: 55%;
  left: 50%;
}

.middle-circle {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 30%;
  border: 1.5px hsl(32, 49%, 92%) solid;
}

.circle-shape {
  display: flex;
  flex-wrap: wrap;
  /* border: solid 1.5px hsl(203, 3%, 20%); */
  background-color: hsl(203, 3%, 20%);
  color: hsl(32, 49%, 92%);
  border-radius: 50%;
  justify-content: center;
  align-content: center;
  padding: 0.4rem;
}

.label-button-container {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline;
  text-align: center;
}

.thumbnail-label {
  /* background-color: white; */
  background-color: hsl(203, 3%, 20%);
  color: hsl(32, 49%, 92%);
  opacity: 1;
  border: 1.5px hsl(32, 49%, 92%) solid;
  border-radius: 3px;
  padding: 10px;
  text-align: center;
  align-items: baseline;
}

.mobile-label-button-container {
  display: none;
}

.mobile-circular-label-container {
  display: none;
}

/* Footer */

footer {
  color: #B3BFBC;
  padding: 0;
  margin: 0;
  text-align: center;
}

footer p {
  margin-top: 1rem;
}

/* Contact Form */

#contact-main {
  margin-top: 40px;
}

#contact-main a {
  text-decoration: none;
}

#contact-form-container h1 {
  color: hsl(203, 3%, 20%);
  margin-top: 4%;
}

#contact-form-container {
  max-width: 600px;
  border: 1px solid black;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  vertical-align: top;
  background-color: hsl(32, 49%, 92%);
  color: black;
  text-align: center;
  margin: 1rem auto 2rem auto;
}

#contact-form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.form-row {
  width: 94%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

#name {
  width: 37%;
  margin-right: 3%;
}

#email {
  width: 50%;
}

#subject, #message {
  width: 100%;
}

#contact-form input[type=text], #contact-form input[type=email], #contact-form textarea {
  padding: 5px 10px;
}

#contact-form textarea {
  height: 175px;
  resize: vertical;
}

#submit-row {
  justify-content: flex-end;
}

#contact-form .contact-button {
  width: 135px;
  padding: 5px;
}

#contact-submission-status {
  margin: 1rem;
}

#contact-main .contact-icons-container {
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

#contact-main .contact-icons-container a {
  margin: 0 8px;
}

#contact-main footer {
  margin-bottom: 25px;
}